.content {
  box-sizing: border-box;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 7rem;
  img {
    max-width: 600px;
    border-radius: 2rem;
    margin: 0 auto;
    margin-top: 3rem;
  }
  h1 {
    font-size: 3.6rem;
    line-height: 4rem;
    font-weight: bold;
  }
  p {
    margin-top: 1.5rem;
    font-size: 1.4rem;
    color: var(--color-secondary);
    span {
      background-color: var(--color-primary);
      color: #fff;
    }
  }
}

.team {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  gap: 5rem;
  margin-bottom: 5rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.links {
  margin-top: 0.7rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.teamMember {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  img {
    width: 96px;
    height: 96px;
    border-radius: 50%;
  }
}

.teamMemberInfo {
  span {
    font-weight: bold;
    font-size: 1.2rem;
  }
  p {
    margin-top: 0.5rem;
    color: var(--color-secondary);
  }
}
