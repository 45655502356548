.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  gap: 2rem;
  box-sizing: border-box;
  padding: 4rem;
  @media screen and (max-width: 768px) {
    padding: 1rem 2rem;
  }
}
.title {
  text-align: center;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.3;
  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}
.description {
  max-width: 950px;
  margin: 0 auto;
  margin-top: 1rem;
  text-align: center;
  color: var(--color-grey);
  font-size: 1.1rem;
  line-height: 1.4;
  margin-bottom: 1.5rem;
  p {
    color: var(--color-grey);
  }
}
.body {
  flex-grow: 1;
  @media screen and (max-width: 768px) {
    padding-bottom: 5rem;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    width: 22px;
    height: 22px;
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}
.successIcon {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  svg {
    width: 40px;
    height: 40px;
  }
}
