.wrapper {
  box-sizing: border-box;
  padding: 1rem;
  background-color: #020312;
  border-radius: 12px;
  position: relative;
}
.title {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  div {
    color: var(--color-secondary);
    font-weight: 400;
  }
}
.floatBadge {
  position: absolute;
  top: -10px;
  right: -5px;
}
.list {
  margin-top: 0.2rem;
  display: flex;
  gap: 1rem;
  font-size: 0.875rem;
  color: var(--color-secondary);
}
.current {
  p {
    font-size: 0.875rem;
    color: var(--color-secondary);
    margin-bottom: 0.2rem;
  }
}
.progress {
  height: 3px;
  margin-top: 0.3rem;
  border-radius: 8px;
  overflow: hidden;
  background: red;
}
.line {
  display: inline-block;
  border-radius: 8px;
  height: 3px;
  background-color: var(--color-primary);
}
.queue {
  margin-top: 0.5rem;
  p {
    font-size: 0.875rem;
    color: var(--color-secondary);
    margin-bottom: 0.2rem;
  }
}
.queueList {
  display: flex;
  gap: 0.3rem;
  & > div {
    flex-grow: 1;
  }
}
.queueItem {
  height: 3px;
  border-radius: 8px;
  background: #37423a;
}
