:root {
  --color-primary: #ffe74c;
  --color-success: #44d72c;
  --color-secondary: #a1a1aa;
  --color-surface: #141414;
  --color-surface-light: #18181b;
  --color-surface-lighter: linear-gradient(0deg, #1a202f 100%, rgba(26, 32, 47, 0) 672.83%);
  --color-text: #f4f4f4;
  --color-white: #fff;
  --color-black: #000;
  --color-grey: rgb(107, 114, 128);
  --color-grey-light: #939db8;
  --color-red: #ed1c24;

  /* buttons */
  --color-bg-button-secondary: #3f3f46;
  --color-button-border: #3f3f46;

  /* inputs */
  --color-border-input: rgb(63, 63, 70);
  --input-disabled-bg: #f9fafb;
  --color-input-prefix-bg: rgba(63, 63, 70, 0.55);
  --color-input-prefix: rgb(127, 134, 148);
  --color-input-bg: #1a1a1b;

  /* tabs */
  --tabs-bg: #1a1a1b;
  --tab-active-bg: #3f3f46;

  /* modals */
  --modal-bg: #1f1f22;
  --modal-action-bg: #f9fafb;
  --notification-bg: #1f1f22;
  --modal-surface: rgba(0, 0, 0, 0.45);

  /* shadows */
  --active-tab-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
  --shadow-button: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(51, 65, 85, 0.1) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  --shadow-input: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow-1: rgba(0, 0, 0, 0.1);
  --shadow-2: rgba(0, 0, 0, 0.2);
  --shadow-5: rgba(0, 0, 0, 0.5);
  --shadow-8: rgba(0, 0, 0, 0.8);
  --shadow-inset: rgba(255, 255, 255, 0.5);
  --shadow-notification: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
  --popup-shadow-sm: 0 4px 8px 0 var(--shadow-2), 0 2px 4px 0 var(--shadow-1);
  --popup-shadow: 0 12px 28px 0 var(--shadow-2), 0 2px 4px 0 var(--shadow-1);

  /* radius */
  --border-radius-s: 0.375rem;
  --border-radius-sm: 0.75rem;

  /* gradients */
  --text-gradient: radial-gradient(54.78% 305% at 50% 50%, #c7c9ff 0, #44d72c 100%);

  /* theme switcher */
  --color-background: #222;
  --color-background-secondary: #383838;

  /* badge colors */
  --color-badge-info: rgb(118, 169, 250);
  --color-badge-info-bg: rgb(55, 65, 81);

  --color-badge-success: rgb(49, 196, 141);
  --color-badge-success-bg: rgb(55, 65, 81);

  --color-badge-fail: rgb(249, 128, 128);
  --color-badge-fail-bg: rgb(55, 65, 81);

  --color-badge-warning: rgb(250, 202, 21);
  --color-badge-warning-bg: rgb(55, 65, 81);
}

html {
  color: var(--color-text);
  font-size: 16px;
  line-height: 150%;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'SF Pro Display', Helvetica, Arial, sans-serif;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

body {
  background-color: var(--color-surface);
  color: var(--color-text);
  font-size: 16px;
}

/* calendar overrides  */
.react-datepicker {
  width: 100% !important;
  box-sizing: border-box !important;
  box-shadow: 0px 5px 10px rgba(33, 33, 36, 0.1);
  background-color: var(--color-surface) !important;
  border: none !important;
}
.react-datepicker__navigation--next::before {
  content: '\e906';
}
.react-datepicker__month-container {
  padding: 5px;
}
.react-datepicker__month {
  margin: 0 0.4rem 0 0.4rem;
  text-align: center;
}
.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
  display: inline-block !important;
  height: 37px !important;
  width: 37px !important;
  line-height: 37px !important;
  text-align: center !important;
  margin: 1px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  border-radius: 50% !important;
}
.react-datepicker__day-name {
  color: var(--color-secondary) !important;
}

.react-datepicker__current-month {
  font-size: 1.6rem;
}
.react-datepicker-time__header,
.react-datepicker__current-month {
  margin-top: 0;
  color: var(--color-text) !important;
  font-weight: 600;
  font-size: 1.2rem;
  height: 2.4rem;
  line-height: 2.4rem;
}
.big-calender .react-datepicker__day--selected {
  color: var(--color-white) !important;
  background-color: var(--color-primary);
}

.react-datepicker__header {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  font-weight: 600;
}

.react-datepicker__time-container {
  border-left: 1px solid var(--color-border-input) !important;
}

.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--color-text) !important;
}
.react-datepicker {
  display: flex;
  font-family: 'SF Pro Display', Helvetica, Arial, sans-serif !important;
}

.react-datepicker__day:hover,
.react-datepicker__time-name:hover {
  color: var(--color-black) !important;
  background-color: var(--tabs-bg) !important;
}

.react-datepicker__time-container {
  background-color: var(--color-surface) !important;
  border-top-right-radius: var(--border-radius-xs);
  border-bottom-right-radius: var(--border-radius-xs);
}
.react-datepicker__time-container .react-datepicker__time {
  background: var(--color-surface) !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  color: var(--color-text) !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: var(--tabs-bg) !important;
  color: var(--color-black) !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 65px;
  top: 10px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 78px !important;
}
.react-datepicker__navigation--previous {
  top: 10px !important;
  left: 12px !important;
}

.react-datepicker {
  display: flex !important;
  background: var(--color-surface) !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--color-primary) !important;
  color: var(--color-black) !important;
}

.react-datepicker__day--today {
  background-color: transparent;
  box-shadow: inset 0px 0px 0px 2px var(--color-secondary);
  box-sizing: border-box;
}
.react-datepicker__day--today.react-datepicker__day--selected {
  box-shadow: none;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: var(--color-primary) !important;
  color: var(--color-black) !important;
}
.react-datepicker__header {
  background: var(--color-surface) !important;
}
.react-datepicker__day--disabled {
  opacity: 0.3;
}
/* calendar overrides : END */

nav ul li a.active {
  background: #262628;
}

@media screen and (max-width: 768px) {
  nav ul li a.active {
    border-bottom: 2px solid #fff;
  }
  nav ul li a.active span {
    color: #fff;
  }
}

/* Стилизация всего ползунка */
input[type='range'] {
  -webkit-appearance: none; /* Убираем стандартный стиль в Webkit-браузерах */
  width: 100%; /* Ширина на всю доступную длину */
  margin: 10px 0; /* Отступ сверху и снизу */
}

/* Стилизация трека */
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 4px; /* Скругление углов трека */
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

/* Стилизация ручки */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Убираем стандартный стиль в Chrome */
  border: none; /* Убираем границу */
  height: 16px; /* Высота ручки */
  width: 16px; /* Ширина ручки */
  border-radius: 50%; /* Скругляем ручку, делая ее круглой */
  background: var(--color-primary); /* Фон ручки */
  margin-top: -8px; /* Подгоняем ручку по вертикали */
  cursor: pointer; /* Курсор в виде указателя */
}

input[type='range']:focus::-webkit-slider-thumb {
  background: #0056b3;
}

/* Стиль для Firefox */
input[type='range']::-moz-range-track {
  background: #ddd;
  border-radius: 4px;
}

input[type='range']::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--color-primary);
  cursor: pointer;
}

/* Стиль для IE */
input[type='range']::-ms-track {
  background: transparent; /* Необходимо сделать фон прозрачным для IE */
  border-color: transparent;
  color: transparent;
}

input[type='range']::-ms-fill-lower {
  background: #ddd;
  border-radius: 4px;
}

input[type='range']::-ms-fill-upper {
  background: #ddd;
  border-radius: 4px;
}

input[type='range']::-ms-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--color-primary);
  cursor: pointer;
}

/* Дополнительные стили для фокуса */
input[type='range']:focus {
  outline: none; /* Убираем контур при фокусе для всех браузеров */
}
