.textRow {
  height: 38px;
  display: block;

  background: linear-gradient(0.25turn, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0)),
    linear-gradient(var(--colorSecondaryLight), var(--colorSecondaryLight)),
    radial-gradient(38px circle at 19px 19px, var(--colorSecondaryLight) 50%, rgba(255, 255, 255, 0) 51%),
    linear-gradient(var(--colorSecondaryLight), var(--colorSecondaryLight));
  background-repeat: no-repeat;

  background-size: 100%;
  border-radius: var(--border-radius-s);
}
.formStream {
  padding: 1.4rem 2rem;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
}

.formStreamNew {
  padding: 1.4rem 0;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
}
.label {
  width: 100px;
  height: 20px;
  margin-bottom: 0.2rem;
  background: linear-gradient(0.25turn, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0)),
    linear-gradient(var(--colorSecondaryLight), var(--colorSecondaryLight)),
    radial-gradient(38px circle at 19px 19px, var(--colorSecondaryLight) 50%, rgba(255, 255, 255, 0) 51%),
    linear-gradient(var(--colorSecondaryLight), var(--colorSecondaryLight));
  background-repeat: no-repeat;

  background-size: 100%;
  border-radius: var(--border-radius-s);
}

.image {
  --colorSecondaryLight: #171c18;
  aspect-ratio: 13/19;
  display: flex;
  min-width: 250px;
  position: relative;
  width: 250px;
  background: linear-gradient(0.25turn, rgba(255, 255, 255, 0), #020202, rgba(255, 255, 255, 0)),
    linear-gradient(var(--colorSecondaryLight), var(--colorSecondaryLight)),
    radial-gradient(38px circle at 19px 19px, var(--colorSecondaryLight) 50%, rgba(255, 255, 255, 0) 51%),
    linear-gradient(var(--colorSecondaryLight), var(--colorSecondaryLight));
  background-repeat: no-repeat;

  background-size: 100%;
  border-radius: var(--border-radius-s);
  @media screen and (max-width: 768px) {
    min-width: 150px;
    width: 150px;
  }
}

.progress {
  --colorSecondaryLight: #37423a;
  aspect-ratio: 13/19;
  display: flex;
  position: relative;
  width: 100%;
  height: 3px;
  background: linear-gradient(0.25turn, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0)),
    linear-gradient(var(--colorSecondaryLight), var(--colorSecondaryLight)),
    radial-gradient(38px circle at 19px 19px, var(--colorSecondaryLight) 50%, rgba(255, 255, 255, 0) 51%),
    linear-gradient(var(--colorSecondaryLight), var(--colorSecondaryLight));
  background-repeat: no-repeat;

  background-size: 100%;
  border-radius: var(--border-radius-s);
}

.imageCard {
  background: linear-gradient(0.25turn, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0)),
    linear-gradient(var(--colorSecondaryLight), var(--colorSecondaryLight)),
    radial-gradient(38px circle at 19px 19px, var(--colorSecondaryLight) 50%, rgba(255, 255, 255, 0) 51%),
    linear-gradient(var(--colorSecondaryLight), var(--colorSecondaryLight));
  background-repeat: no-repeat;

  background-size: 100%;
  border-radius: var(--border-radius-s);
  aspect-ratio: 1/1;
}

.shortRow {
  height: 38px;
  display: block;

  width: 150px;

  background: linear-gradient(0.25turn, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0)),
    linear-gradient(var(--colorSecondaryLight), var(--colorSecondaryLight)),
    radial-gradient(38px circle at 19px 19px, var(--colorSecondaryLight) 50%, rgba(255, 255, 255, 0) 51%),
    linear-gradient(var(--colorSecondaryLight), var(--colorSecondaryLight));
  background-repeat: no-repeat;

  background-size: 100%;
  border-radius: var(--border-radius-s);
}
.card {
  width: 300px;
  height: 200px;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
}
.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: auto;
}

// accessibility fix - use animation only if it enabled in browsers
@media screen and (prefers-reduced-motion: no-preference) {
  .textRow,
  .shortRow,
  .image,
  .label,
  .progress,
  .skeleton-post__media,
  .skeleton-post__header-logo,
  .card,
  .imageCard,
  .chart {
    animation: loading 2s infinite;
  }
}

@keyframes loading {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
