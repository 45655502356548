.hero {
  box-sizing: border-box;
  padding: 5rem 1rem;
  //background-image: url('../../assets/images/bg-3.svg');

  padding-top: 10rem;
  position: relative;
  h1 {
    font-size: 3.6rem;
    line-height: 1.2;
    font-weight: 600;
    text-align: center;
    //color: rgb(15, 23, 42);
    span {
      color: var(--color-primary);
      font-size: 4.5rem;
      line-height: 1;
      @media screen and (max-width: 768px) {
        font-size: 17vw;
      }
    }
  }
  p {
    color: var(--color-secondary);
    margin-top: 2rem;
    line-height: 1.6;
    font-size: 1.425rem;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    & > div {
      padding: 0;
    }
  }
}

.surface {
  position: relative;
  background-image: url('https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/90214c73-1de7-4e65-2dc4-dff72374a000/medium');
  background-size: cover;
  background-repeat: no-repeat;
  top: -20px;
  //background-position: -50px 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(19, 19, 19, 0.72) 0%, rgba(19, 19, 19, 0.999) 100%);
    @media screen and (max-width: 768px) {
      background-image: linear-gradient(180deg, rgba(19, 19, 19, 0.86) 0%, rgba(19, 19, 19, 0.999) 100%);
    }
  }
}
.featuresList {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 2rem;

  img {
    border-radius: var(--border-radius-s);
    box-shadow: 0 1px 12px 2px #0a001f0d;
    height: 368px;
    width: 100%;
    object-fit: cover;
  }

  h3 {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
  p {
    color: var(--color-secondary);
    line-height: 1.4;
    margin-top: 0.8rem;
    font-size: 1.2rem;
  }
}

.flexRow {
  display: flex;
  gap: 2.5rem;
  width: 100%;
  & > div {
    flex-grow: 1;
  }
}

@media screen and (max-width: 768px) {
  .featuresList,
  .flexRow {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .featureItem {
    display: flex !important;
    flex-direction: column;
    width: 100% !important;
    h3 {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
    p {
      font-size: 1.1rem;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}
.featureItem {
  //overflow: hidden;
  width: 50%;
  //background-color: var(--color-dashboard-bg);

  margin-bottom: 2rem;
}
.featureItemText {
  box-sizing: border-box;
  padding: 1rem;
  text-align: left;
  h3,
  p {
    text-align: left;
  }
}
.professions {
  margin: 0 auto;
  margin-top: 2rem;
  //max-width: 940px;
  grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
  display: grid;
  gap: 4rem;
}
.stackedAvatars {
  display: flex;
  @media screen and (max-width: 768px) {
    p {
      font-size: 0.875rem !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .professions {
    gap: 2rem;
  }
  .profession {
    margin-bottom: 0rem !important;
  }
}
.earnContent {
  max-width: 50%;
}
.earn {
  display: flex;
  align-items: center;
  gap: 5rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
  h2 {
    font-weight: 600;
    font-size: 2.2rem;
    margin-bottom: 1rem;
    line-height: 2.6rem;
  }
  p {
    color: var(--color-secondary);
    font-size: 1rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .earn {
    flex-direction: column;
    gap: 2rem;
  }
  .earnContent {
    max-width: 100%;
    width: 100%;
  }
}

.earnImage {
  video {
    max-width: 100%;
    height: auto;
    border-radius: 26px;
  }
  @media screen and (max-width: 768px) {
    iframe {
      max-width: 100%;
      height: 240px;
    }
  }
}

.professionContent {
  width: 450px;
  h3 {
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
  p {
    color: var(--color-secondary);
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.5;
  }
}
.professionSuggest {
  display: flex;
  flex-direction: column;
  display: none;
}
.example {
  box-sizing: border-box;
  padding: 0.5rem;

  display: inline-block;
  border-radius: var(--border-radius-s);
  box-shadow: var(--card-shadow);
  border: 1px solid var(--color-card-border);
  background-color: var(--color-card-bg);
  font-size: 0.8rem;
}

.professionImage {
  max-width: 280px;
  img {
    max-width: 100%;
    height: auto;
  }
}

.profession {
  margin-bottom: 3rem;
  align-items: center;
  display: flex;
  gap: 3rem;

  &:nth-child(2n) {
    justify-content: end;
    // & > div:first-child {
    //   order: 3;
    // }
  }
}
.bonus {
  font-size: 0.75rem;
  color: var(--color-secondary);
  text-align: center;
}
.docs {
  button span {
    color: var(--color-secondary);
  }
}
.grid {
  margin-top: 2rem;
}
.gridItem {
  box-sizing: border-box;
  width: 240px;
  border-radius: 20px;
  @media screen and (max-width: 768px) {
    width: 150px;
    border-radius: 12px;
  }
}
.form {
  max-width: 500px;
  margin-top: 3rem;
  display: flex;
  gap: 1rem;
  justify-content: left;
  align-items: center;
  & > div {
    margin-top: 0;
  }
  button {
    height: 45px;
    svg {
      margin-left: 10px !important;
    }
    span {
      font-size: 1rem;
    }
  }
  p {
    text-align: center;
    margin-top: 0.5rem;
    font-size: 0.825rem;
    color: var(--color-secondary);
  }
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      margin-left: 0.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.launch {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  span {
    display: inline-block;
    box-sizing: border-box;
    padding: 0.1rem 0.75rem;
    background: var(--color-background-secondary);
    font-size: 0.8rem;
    letter-spacing: 0.2px;
    font-weight: 500;
    border-radius: var(--border-radius-sm);
  }
}
.trendList {
  margin-top: 1.5rem;
  display: grid;
  gap: 1rem;
  // max-height: 100%;
  // overflow-y: auto;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  padding-bottom: 5rem;
  grid-auto-rows: 0fr;
}
.others {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-left: 1rem;
    font-size: 0.925rem !important;
    color: var(--color-secondary);
    margin-top: 0;
  }
  img {
    max-height: 36px;
    border-radius: 50%;
    margin: 0 -6px;
    vertical-align: middle;
    border: 2px solid var(--color-surface);
  }
}
.trend {
  margin-top: 5rem;
  h3 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    a {
      color: var(--color-primary);
      border-bottom: 2px dashed var(--color-primary);
      margin-right: 5px;
    }
  }
  h3 + p {
    color: var(--color-secondary);
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    line-height: 1.8rem;
    margin: 0 auto;
    text-align: center;
    margin-top: 1rem;
  }
}
.advantages {
  margin-top: 5rem;
  h2 {
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    a {
      color: var(--color-primary);
      border-bottom: 2px dashed var(--color-primary);
      margin-right: 5px;
    }
  }
  h2 + p {
    color: var(--color-secondary);
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin: 0 auto;
    text-align: center;
    margin-top: 1rem;
  }
  p {
    font-size: 1rem;
  }
}
.advantagesList {
  margin-top: 2rem;
}
.advantagesCenter {
  width: 26%;
  min-width: 26%;
  margin: 0 2rem;
  img {
    width: 100%;
    height: auto;
    position: sticky;
    top: 20px;
  }
}
.advantage {
  margin-bottom: 1.5rem;
}
.advantage h3 {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: var(--color-secondary);
}
.promptList {
  display: flex;
  gap: 1.4rem;
}
.producthunt {
  text-align: center;
  p {
    margin-top: 0rem;
    font-size: 0.825rem;
  }
}
.prompt {
  box-sizing: border-box;
  padding: 1.1rem;
  width: 25%;
  max-width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 500;

  border-radius: var(--border-radius-s);
  background-color: var(--color-advantage-item);
  p {
    margin-top: 0.5rem;
  }
  span {
    display: inline-block;
    text-transform: uppercase;
    color: var(--color-primary);
    padding: 0.2rem 0.6rem;
    line-height: 1;
    border-radius: var(--border-radius-s);
    font-size: 0.725rem;
    font-weight: 500;
    background-color: rgba(63, 136, 197, 0.2);
  }
}

.professionBadge {
  display: inline-block;
  text-transform: uppercase;
  color: var(--color-primary);
  padding: 0.2rem 0.6rem;
  line-height: 1;
  border-radius: var(--border-radius-s);
  font-size: 0.725rem;
  font-weight: 500;
  background-color: rgba(63, 136, 197, 0.2);
}

.more {
  margin-top: 3rem;
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 5rem;
}

.cost {
  @media screen and (max-width: 769px) {
    & section div:nth-child(2) {
      max-height: initial;
    }
  }
}
.advantagesRight,
.advantagesLeft {
  width: 37%;
}
.advantageItem {
  box-sizing: border-box;
  padding: 1.2rem;
  margin: 1rem 0;
  border-radius: var(--border-radius-s);
  background-color: var(--color-advantage-item);
  width: 100%;
  align-self: flex-start;
  span {
    font-weight: 600;
    line-height: 1.4rem;
    display: block;
  }
  p {
    color: var(--color-secondary);
    margin-top: 0.3rem;
    line-height: 1.4rem;
  }
}

.chart {
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
  }
  h2 + p {
    color: var(--color-secondary);
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
}
.chartContent {
  max-width: 30%;
  min-width: 30%;
  margin-right: 2rem;
}
.chartContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border-input);
}

.calculator {
  margin-top: 5rem;

  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
  }
  h2 + p {
    color: var(--color-secondary);
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
}
.calculatorContainer {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
}
.calculatorContainerText {
  width: 40%;
  margin-right: 2rem;
  span {
    display: block;
    margin-top: 1.5rem;
    font-size: 0.755rem;
    color: var(--color-secondary);
    line-height: 1.2rem;
  }
}
.calculatorContainerForm {
  box-sizing: border-box;
  padding: 1.2rem;
  width: 450px;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border-input);
  span {
    padding-top: 0;
  }
}

.duration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
.durationActions {
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin: 0 0.4rem;
    text-align: center;
    background: var(--color-border-input);
    color: var(--color-text);
    cursor: pointer;
  }
}

button.selectedDay {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.calculatorTotal {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 1rem;
    color: var(--color-secondary);
  }
  p {
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--color-primary);
  }
  button {
    span {
      font-size: 1rem !important;
      color: var(--color-white) !important;
    }
  }
}

.faq {
  margin-top: 5rem;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }
  h2 + p {
    color: var(--color-secondary);
    margin: 0 auto;
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.8rem;
  }
}

.testimonial {
  margin-top: 5rem;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }
  h2 + p {
    color: var(--color-secondary);
    margin: 0 auto;
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.8rem;
  }
  @media screen and (max-width: 768px) {
    margin-top: 2rem;
    h2 {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 1.2;
      text-align: center;
    }
  }
}

.faqList {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.faqItem {
  width: 30%;
  margin-bottom: 2rem;
  span {
    font-weight: bold;
  }
  p {
    color: var(--color-secondary);
    margin-top: 0.3rem;
  }
}

.blog {
  margin-top: 5rem;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }
  h2 + p {
    color: var(--color-secondary);
    margin: 0 auto;
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.8rem;
  }
}

.blogList {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.blogItem {
  width: 30%;
  margin-bottom: 2rem;
  img {
    border-radius: var(--border-radius-s);
    margin-bottom: 0.5rem;
    max-width: 100%;
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: top;
  }
  a {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.4;
    &:hover {
      color: var(--color-primary);
    }
  }
  p {
    color: var(--color-secondary);
    margin-top: 0.3rem;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 750px) {
  .advantagesList {
    display: block;
  }
  .advantagesLeft {
    width: 100%;
  }
  .advantagesCenter {
    width: 100%;
    text-align: center;
    margin-left: 0;
    img {
      max-width: 300px;
      margin: 0 auto;
    }
  }
  .advantagesRight {
    width: 100%;
  }

  .professionImage {
    height: auto;
    order: -1;
  }
  .professionContent {
    width: 100%;
    max-width: 100%;
  }
  .profession {
    flex-direction: column;
    gap: 1rem;
  }
  .more {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 640px) {
  .hero {
    padding: 3rem 1rem;
  }
  .promptList {
    overflow-x: auto;
  }
  .prompt {
    width: 250px;
    max-width: 250px;
    margin-bottom: 1rem;
    min-width: 250px;
  }
  .advantages {
    margin-top: 2rem;
    h2 {
      font-size: 1.9rem;
      font-weight: 600;
    }
    h2 + p {
      color: var(--color-secondary);
      margin-top: 1rem;
      max-width: 750px;
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }

  .faq {
    margin-top: 3rem;
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
      text-align: center;
    }
    h2 + p {
      color: var(--color-secondary);
      margin-top: 1rem;
      text-align: center;
      max-width: 750px;
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
  .faqItem {
    width: 47%;
  }

  .calculator {
    margin-top: 3rem;

    h2 {
      font-size: 1.6rem;
      font-weight: 600;
    }
    h2 + p {
      color: var(--color-secondary);
      margin-top: 1rem;
      max-width: 750px;
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
  .calculatorContainer {
    display: block;
  }
  .calculatorContainerText {
    width: 100%;
    margin-bottom: 2rem;
  }
  .calculatorContainerForm {
    width: 100%;
  }

  .chart {
    margin-top: 3rem;
    display: block;
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
    }
    h2 + p {
      color: var(--color-secondary);
      margin-top: 1rem;
      max-width: 750px;
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }

  .chartContent {
    width: 100%;
    margin-bottom: 2rem;
    max-width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .hero h1 {
    font-size: 11vw;
  }
  .hero p {
    font-size: 4vw;
    br {
      display: none;
    }
  }
  .hero {
    padding-top: 8rem;
  }

  .form p {
    font-size: 3vw;
  }

  .advantages h2 {
  }

  .faqItem {
    width: 100%;
  }
}

@media screen and (max-width: 460px) {
  .calculatorContainerForm span {
    font-size: 0.925rem;
  }
  .durationActions button {
    margin: 0 1vw;
    width: 11vw;
    height: 11vw;
  }
  .chartContainer {
    padding: 0;
  }
}

html:lang(de) {
  @media screen and (max-width: 460px) {
    .hero h1 {
      font-size: 9vw;
    }
    .hero p {
      font-size: 3.1vw;
    }
  }
}

.video {
  max-width: 860px;
  margin: 0 auto;
  video {
    border-radius: 12px;
    box-shadow: rgba(10, 0, 31, 0.05) 0px 1px 24px 4px;
    max-width: 100%;
    width: 100%;
  }
}
.nocard {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  justify-content: center;
  margin-top: 1rem;
  color: var(--color-secondary);
  font-size: 0.785rem;
}
.try {
  margin-bottom: 5rem;
  margin-top: 5rem;
  text-align: center;
  box-sizing: border-box;
  padding: 3rem;
  border-top-left-radius: 64px;
  border-top-right-radius: 64px;
  background: linear-gradient(
    0.0000056456212718103416deg,
    var(--token-39970de2-5fe4-42ad-ab53-d8274d5c9ad9, var(--color-surface)) 0%,
    var(--token-cb02979d-a542-4279-9758-de55dd3af50c, #1e1c1c) 100%
  );
  h3 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  p {
    max-width: 750px;
    font-size: 1.2rem;
    margin: 0 auto;
    line-height: 1.8rem;
    color: var(--color-secondary);
  }
  button {
    align-self: center;
    display: inline-block;
    margin-top: 2rem;
    span {
      font-size: 1.2rem;
    }
  }
}
.newFeaturesList {
  margin-top: 3rem;
}
.codeExample {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.codeBlock {
  margin-top: 1rem;
  width: 100%;
}
.newFeatureItem {
  backdrop-filter: blur(4px);
  background: #020312;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: 4rem;
  gap: 2rem;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  margin-bottom: 2rem;
  h3 {
    font-size: 2.4rem;
    line-height: 3rem;
    font-weight: bold;
  }
  p {
    margin-top: 1rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: var(--color-secondary);
  }
}
.newFeatureItemImage {
  position: absolute;
  bottom: -5px;
  top: 15%;
  border-radius: var(--border-radius-s);
  right: 0;
  overflow: hidden;
  width: 45%;
  img {
    width: 100%;
    height: 100%;
    object-position: top;
    object-fit: cover;
  }
}
.newFeatureItemContent {
  max-width: 50%;
}
.newFeatureItemContentAction {
  margin-top: 2.5rem;
  a {
    display: inline-block;
  }
  button {
    span {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .newFeatureItem {
    flex-direction: column;
    padding: 2rem 1rem;
    h3 {
      font-size: 2.2rem;
      line-height: 2.6rem;
      font-weight: bold;
    }
  }
  .newFeatureItemContent {
    max-width: 100%;
    width: 100%;
  }
  .newFeatureItemImage {
    position: static;
    order: -1;
    width: 100%;
    border-radius: 20px;
    margin-top: -1rem;
  }
  .trend h3 a {
    font-size: 2.2rem;
  }
  .trend {
    margin-top: 2rem;
  }
}

.students {
  text-align: center;
  margin-top: 2rem;
  p {
    font-weight: 500;
  }
}
.list {
  margin-top: 1rem;
  text-align: center;
  img {
    width: 550px;
    height: auto;
  }
}

.reviews {
  margin-top: 5rem;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }
  h2 + p {
    color: var(--color-secondary);
    margin: 0 auto;
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.8rem;
  }
}

.reviewsList {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.reviewItem {
  box-sizing: border-box;
  padding: 1rem;
  width: calc(33% - 1rem);
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-weight: 500;
  }
}
.reviewItemAuthor {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  span {
    display: flex;
    align-items: center;
    color: var(--color-secondary);
    font-size: 0.875rem;
  }
  img {
    margin-right: 5px;
  }
}

@media screen and (max-width: 768px) {
  .reviewItem {
    width: 100%;
  }
}

.download {
  margin-top: 3rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  span {
    font-weight: bold;
    font-size: 1.2rem;
  }
}
.downloadList {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}
.downloadItem {
  box-sizing: border-box;
  max-width: 160px;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}

.codeExample {
  & > div {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .download {
    display: none;
  }
  .blogList {
    flex-direction: column;
  }
  .blogItem {
    width: 100%;
  }
  .codeExample {
    & > div {
      max-width: 100%;
    }
  }
}

.muvon {
  font-size: 0.725rem;
  justify-content: left;
  display: flex;
  margin-bottom: 0.5rem;
  color: var(--color-secondary);
  svg {
    max-height: 18px;
    width: 50px;

    margin-left: 4px;
    vertical-align: text-top;
  }
}

.ph {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.heroTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.heroTopText {
  max-width: 40%;
  text-align: left;
  h1 {
    text-align: left;
  }
  p {
    text-align: left;
  }
}

.heroTopImages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  img {
    max-width: 100%;
  }
}
.heroTopImages {
  max-width: 55%;
}

.video {
  margin-top: 2rem;
}

/** prices **/
.prices {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }
  h4 {
    font-size: 2.2rem;
    text-align: left;
    line-height: 1.2;
    font-weight: 600;
    span {
      font-size: 1rem;
      color: var(--color-secondary);
      font-weight: 400;
    }
  }
}
.comparison {
  margin-top: 5rem;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }
  h3 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    margin: 2rem 0;
  }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 1.6rem;
    }
    h3 {
      font-size: 1.2rem;
    }
  }
}
.comparisonWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.comparisonInput {
  display: flex;
  justify-content: center;
  gap: 10px;
  img {
    max-width: 120px;
    border-radius: 8px;
    @media screen and (max-width: 768px) {
      // max-width: 100%;
      width: 100%;
    }
  }
}
.output {
  display: flex;
  gap: 2rem;
  max-width: 900px;
  margin: 3rem auto;
  & > div {
    flex-grow: 1;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.outputHeader {
  font-weight: 500;
  font-size: 1.4rem;
}
.outputDescription {
  margin-top: 0.5rem;
  color: var(--color-secondary);
  font-size: 0.825rem;
  line-height: 1.2;
  b {
    color: #fff;
  }
}
.outputItem {
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background: #020312;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  &:last-child {
    border: 1px solid var(--color-primary);
  }
  @media screen and (max-width: 768px) {
    img {
      height: auto;
    }
  }
}
.paidItem {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0.5rem;
  div {
    font-size: 0.875rem;
  }
}
.outputFeatures {
  margin-top: 2rem;
}
.outputImages {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  img {
    width: 50%;
    max-width: 100%;
    border-radius: 8px;
  }
}
.comparisonInfo {
  box-sizing: border-box;
  padding: 1rem;
  color: var(--color-secondary);
  border-radius: 8px;
  background-color: #1e1c1c;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 1rem;
}
.fullPrice {
  text-align: left;
  color: var(--color-secondary);
}
.dealWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  align-items: center;
}
.margin {
  height: 31px;
}
.oldPrice {
  text-decoration: line-through;
  color: var(--color-secondary);
  font-size: 1.2rem;
}
.deal {
  box-sizing: border-box;
  padding: 0.1rem 0.5rem;
  border-radius: 20px;
  display: flex;
  font-size: 0.825rem;
  color: var(--color-white);
  text-align: left;
  opacity: 0.6;
  background-color: var(--color-primary);
}
.hero {
  text-align: center;
}
.hint {
  color: var(--color-secondary);
  font-size: 0.725rem;
  line-height: 1rem;
}
.description {
  color: var(--color-secondary);
  margin-top: 1rem;
  font-size: 1.1rem;
  line-height: 1.8rem;
  text-align: center;
}
.advantages {
  margin-bottom: 1rem;
  margin-top: 1rem;
  p {
    // font-size: 0.825rem !important;
    margin-bottom: 0.2rem;
    //color: var(--color-secondary);
    svg {
      vertical-align: top;
      margin-right: 5px;
      max-width: 14px;
    }
  }
}
.common {
  margin: 1rem auto;
  h4 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.6rem;
  }
}

.pricesWrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    // max-height: 50vh;
    // overflow-y: auto;
    padding-top: 1rem;
  }
}

.pricesBox {
  display: inline-block;
  position: relative;
  ul {
    display: flex;
    align-self: center;
  }
}

.discount {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  padding: 0.2rem 0.4rem;
  border-radius: 15px;
  font-weight: 500;
  line-height: 1;
  font-size: 0.685rem;
  color: rgb(205, 52, 32);
  background-color: rgb(248, 171, 158, 0.9);
  transform: rotate(-15deg);
  pointer-events: none;
  right: 52px;
  top: -9px;
}

.bonus {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  line-height: 1;
  padding: 0.2rem 0.4rem;
  border-radius: 15px;
  font-weight: 500;
  font-size: 0.685rem;
  top: -9px;
  right: -21px;
  color: var(--color-primary);
  background-color: rgba(56, 139, 253, 0.1);
  transform: rotate(-15deg);
  pointer-events: none;
}
.tab {
  margin-top: 1rem;
  p {
    text-align: center;
  }
}
.list {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  &:first-child {
    width: 100%;
  }
}
.calculation {
  margin-top: 0.3rem;
  color: var(--color-secondary);
  font-size: 0.875rem;
}
.actionWrapper {
  margin-top: auto;
  button {
    width: 100%;
    justify-content: center;
  }
}
.cancel {
  margin-top: 0.2rem;
  font-size: 0.7rem !important;
  text-align: center !important;
  color: var(--color-secondary);
}
.priceAmount {
  font-weight: 600;
  font-size: 1.4rem;
  margin-right: 0.25rem;
}
.creditsAmount {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding: 0.1rem 0.3rem;
  line-height: 1;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-primary);
  background-color: rgba(56, 139, 253, 0.1);
  border-radius: var(--border-radius-s);
}
.priceItem {
  box-sizing: border-box;
  padding: 1rem;
  border-radius: var(--border-radius-s);
  min-width: 340px;
  background-color: var(--color-dashboard-bg);
  box-shadow: var(--card-shadow);
  border: 1px solid #1e1c1c;
  align-self: normal;
  display: flex;
  flex-direction: column;
  //background: #1e1c1c;
  align-self: baseline;

  &:nth-child(2) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #020312;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }

  // @media screen and (min-width: 768px) {
  //   &:last-child {
  //     width: 100%;
  //     max-width: 870px;
  //     div {
  //       display: flex;
  //       flex-wrap: wrap;
  //       gap: 0.7rem;
  //     }
  //   }
  // }

  h3 {
    //font-weight: 600;
    //font-size: 0.5rem 1rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      text-transform: uppercase;
      color: var(--color-primary);
      padding: 0.1rem 0.3rem;
      line-height: 1;
      margin-left: 0.3rem;
      border-radius: var(--border-radius-s);
      font-size: 0.7rem;
      font-weight: 500;
      background-color: rgba(63, 136, 197, 0.2);
    }
  }
  p {
    text-align: left;
    font-size: 1rem;
    svg {
      position: relative;
      top: 2px;
    }
    b {
      font-weight: 600;
    }
  }
}
.calculation {
  font-size: 0.925rem !important;
}
.valuable {
  margin-left: auto;
}
@media screen and (max-width: 768px) {
  .list {
    flex-direction: column;
    gap: 1rem;
  }

  .priceItem {
    width: 100%;
  }
}

.details {
  margin-top: 2rem;
  margin-bottom: 10rem;
}
.box {
  box-sizing: border-box;
  padding: 1rem;
  border-radius: var(--border-radius-s);
  background: #1e1c1c;
  font-size: 0.785rem;
  color: var(--color-secondary);
  margin-bottom: 0.5rem;
}
.showcase {
  display: flex;
  //gap: 0.5rem;
  max-width: 872px;
  flex-wrap: wrap;
  margin: 1rem auto;
}

.showcaseItem {
  box-sizing: border-box;
  padding: 0.2rem;
  text-align: left;
  // border-radius: var(--border-radius-s);
  // background-color: var(--color-dashboard-bg);
  // border: 1px solid var(--color-card-border);
  // box-shadow: var(--card-shadow);
  max-width: 33%;
  width: 33%;
  flex-grow: 1;
  margin-bottom: 0.4rem;
  svg {
    margin-right: 5px;
    max-width: 14px;
  }
  span {
    font-weight: bold;
    font-size: 0.875rem;
    display: flex;
    margin-bottom: 0.2rem;
    align-items: center;
    line-height: 1.2;
  }
  p {
    color: var(--color-secondary);
    text-align: left;
    line-height: 1.3;
    font-size: 0.825rem !important;
  }
}

@media screen and (max-width: 768px) {
  .showcaseItem {
    width: 100%;
    max-width: 100%;
  }
}

.advantageItem {
  box-sizing: border-box;
  padding: 1rem;
  border: 1px solid #1d1b1b;
  border-radius: var(--border-radius-s);
  background: var(--color-background-secondary);
  align-self: baseline;
}

.advantageItemTitle {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.advantageItem p {
  color: var(--color-secondary);
}

.priceItem {
  display: flex;
  text-align: center;
  sup {
    color: var(--color-secondary);
    bottom: 6px;
    left: 2px;
    position: relative;
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 768px) {
  .advantages {
    flex-direction: column;
  }
  .advantageItem {
    width: 100%;
  }
  .heroTop {
    flex-direction: column;
  }
  .heroTopText {
    width: 100%;
    max-width: 100%;
  }
  .heroTopImages {
    width: 100%;
    max-width: 100%;
    margin-top: 2rem;
  }
}

.activeSub {
  border: 1px solid var(--color-primary);
}
.article {
  margin: 1rem;
  font-size: 0.875rem;
  color: var(--color-secondary);
}
