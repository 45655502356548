aside {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  display: flex;
  gap: 17rem;
  align-items: center;
  background: #020312;
  border-bottom: 1px solid var(--color-input-bg);
  position: relative;
  z-index: 1;
  height: 57px;
  min-height: 57px;
  @media screen and (max-width: 768px) {
    gap: 0;
    height: 54px;
    max-height: 54px;
    position: relative;
    z-index: 2;
  }
}
.upsell {
  position: relative;
  button span {
    line-height: 1;
  }
  a > span {
    position: absolute;
    display: block;
    text-align: center;
    left: 0;
    right: 0;
    bottom: -13px;
    font-size: 0.525rem;
    color: var(--color-secondary);
    line-height: 1.2;
  }
}
.headerWrapper {
  display: flex;
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}
.userAreaBottom {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.nav {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.formTitle {
  font-weight: bold;
  margin-top: 1rem;
}
.rightHeaderSection {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.userAreaMobile {
  display: flex;
  @media screen and (min-width: 768px) {
    display: none;
  }
}
