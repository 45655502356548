.header {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 1rem;
}
.content {
  box-sizing: border-box;
  padding: 1rem;
  height: 100%;
  h2 {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
}

.action {
  margin: 3rem 0;
  display: flex;
  justify-content: center;
}
.form {
  width: 360px;
  box-sizing: border-box;
  padding: 0 0.3rem;
  max-width: 360px;
  min-width: 360px;
  border-right: 1px solid var(--color-input-bg);
  height: calc(100vh - 57px);
  max-height: calc(100vh - 57px);
  position: sticky;
  top: 0;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}
.wrapper {
  flex-grow: 1;
  @media screen and (min-width: 768px) {
    width: calc(100% - 360px);
  }
}

.floatStatus {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  z-index: 1;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  span {
    display: flex;
    margin-right: 0.2rem;
    height: 14px;
    width: 14px;
  }
  @media screen and (max-width: 768px) {
    font-size: 0.675rem;
  }
}

.headLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
  h2 {
    white-space: nowrap;
    margin-bottom: 0;
  }
  div {
    max-width: 200px;
    span {
      bottom: 0.4rem;
    }
    label {
      color: var(--color-secondary);
      font-weight: 400;
    }
  }
}
.upsell {
  margin-bottom: 1rem;
}
.upsell a {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border-radius: 18px;
  font-weight: 500;
  font-size: 0.825rem;
  background: var(--color-primary);
  color: #000;
  &:hover {
    opacity: 0.8;
  }
}
