.wrapper {
  position: sticky;
  bottom: 0;
  background: #020312;
  border-top: 1px solid var(--color-input-bg);
  box-sizing: border-box;
  padding: 0 1rem;
  max-height: 64px;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  margin-left: 0;
}
.navLink {
  background: transparent !important;
  font-weight: 500;
  font-size: 0.725rem;
  box-sizing: border-box;
  padding: 0.7rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;
  span {
    color: var(--color-secondary);
  }
}

.current {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  p {
    font-size: 0.875rem;
    color: var(--color-secondary);
    margin-bottom: 0.2rem;
  }
}
.progress {
  height: 3px;
  margin-top: 0.3rem;
  border-radius: 8px;
  overflow: hidden;
  background: red;
}
.line {
  display: inline-block;
  border-radius: 8px;
  height: 3px;
  background-color: var(--color-primary);
}
.home {
  position: relative;
}
.progressCount {
  position: absolute;
  font-size: 10px;
  right: -2px;
  top: 4px;
  box-sizing: border-box;
  padding: 2px 4px;
  background: var(--color-primary);
  color: #000;
  border-radius: 50%;
  line-height: 1;
}
