.modelInfo {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: var(--border-radius-s);
  background-image: linear-gradient(180deg, rgba(19, 19, 19, 0.05) 0%, rgba(19, 19, 19, 0.7) 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0.35rem;
  span {
    font-size: 0.725rem;
    //color: var(--color-secondary);
    line-height: 1.1;
  }
}
.status {
  position: absolute;
  top: 0.1rem;
  left: 0.4rem;
  display: flex;
  align-items: center;
  font-size: 0.605rem;
  font-weight: 500;
  letter-spacing: -0.5px;
  span {
    margin-left: 0.2rem;
    font-size: 0.625rem;
  }
}
.modelItem {
  width: 90px;
  min-width: 90px;
  height: 140px;
  position: relative;
  cursor: pointer;
  border-radius: var(--border-radius-s);
  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    opacity: 0.8;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius-s);
    overflow: hidden;
  }
}
.percent {
  position: absolute;
  top: 20px;
  left: -3px;
}
.status {
  svg {
    margin-left: 0.2rem;
    height: 14px;
    width: 14px;
  }
}

.fallbackBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 0.625rem;
  line-height: 1.2;
  justify-content: center;
  background: linear-gradient(
    0.0000056456deg,
    var(--token-39970de2-5fe4-42ad-ab53-d8274d5c9ad9, var(--color-surface)) 0%,
    var(--token-cb02979d-a542-4279-9758-de55dd3af50c, #1e1c1c) 100%
  );
}
