.content {
  box-sizing: border-box;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 5rem;
  img {
    max-width: 600px;
    border-radius: 2rem;
    margin: 0 auto;
    margin-top: 3rem;
  }
  h1 {
    font-size: 3.6rem;
    line-height: 4rem;
    font-weight: bold;
  }
  p {
    margin-top: 1.5rem;
    font-size: 1.4rem;
    color: var(--color-secondary);
    span {
      background-color: var(--color-primary);
      color: #fff;
    }
  }
}

.steps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 5rem;
  h2 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  p {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: var(--color-secondary);
  }
}
.stepList {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 4rem;
}
.step {
  margin-bottom: 5rem;
  display: flex;
  gap: 5rem;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  img {
    max-width: 320px;
    border-radius: 20px;
    height: auto;
  }
}
.stepNum {
  font-weight: bold;
  color: var(--color-primary);
}
.stepTitle {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 2.4rem;
}
.stepDescription {
  margin-top: 1rem;
  color: var(--color-secondary);
  line-height: 1.7rem;
  span {
    color: var(--color-primary);
  }
  a {
    color: var(--color-primary);
  }
}

.faqList {
  margin-top: 2rem;
}
.faq {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 5rem;

  h2 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  & > p {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: var(--color-secondary);
  }
}

.faqItem {
  margin-bottom: 1rem;
  text-align: left;
  span {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: block;
  }
  p {
    color: var(--color-secondary);
    margin-top: 0.1rem;
    font-size: 1rem;
  }
  a {
    color: var(--color-primary);
  }
}

.submit {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 5rem;

  h2 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  & > p {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: var(--color-secondary);
  }
  a {
    margin-top: 2rem;
    display: flex;
    align-self: center;
    padding: 1rem 1rem;
    font-weight: 500;
    border-radius: var(--border-radius-s);
    color: var(--color-white);
    background: var(--color-primary);
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .hero {
    margin-top: 3rem;
    h1 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
    p {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
    img {
      max-width: 100%;
      height: auto;
      margin-top: 2rem;
    }
  }

  .steps {
    margin-top: 4rem;
    h2 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
  }

  .stepImage {
    order: -1;
  }

  .step {
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 3rem;
    img {
      width: 100%;
      max-width: 100%;
    }
  }

  .faq {
    margin-top: 3rem;
  }

  .submit {
    h2 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
  }
}

.calculator {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 5rem;

  h2 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 3.4rem;
  }
  & > p {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: var(--color-secondary);
  }
}

.calc {
  box-sizing: border-box;
  padding: 1rem;
  margin: 0 auto;
  margin-top: 2rem;

  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  width: 550px;
  input {
    text-align: left;
    height: 55px;
    font-size: 1.2rem;
    font-weight: bold;
  }
}
.note {
  margin-top: 1rem;
  font-size: 0.785rem;
  color: var(--color-secondary);
  text-align: left;
  line-height: 1.2rem;
}
.estimated {
  margin-top: 1rem;
  display: flex;
  line-height: 2rem;
  justify-content: space-between;
  align-items: flex-end;
  p {
    color: var(--color-secondary);
    text-align: left;
    font-size: 1.6rem;
  }
  span {
    font-weight: bold;
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .calc {
    width: 100%;
    label {
      text-align: left;
    }
  }
}
