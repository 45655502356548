.tooltip {
  background: #212124;
  font-size: 12px;
  line-height: 16px;
  color: #fafafa;
  padding: 0.8rem;
  border-radius: var(--border-radius-s);
}
.box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.iconWrapper {
  padding: 0;
  line-height: 1;
  margin: 0 0.2rem;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
}
.badge {
  margin-left: 0.3rem;
  font-size: 8px !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  background: var(--color-surface);
  border-radius: 2px;
  padding: 3px 3px;
  color: var(--color-secondary);
}
