.title {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.list {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  max-width: 100%;
  overflow-y: auto;
  padding-bottom: 1rem;
  margin-top: 0.5rem;
}

.addNewModel {
  width: 90px;
  min-width: 90px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  background: var(--color-background-secondary);
  border-radius: var(--border-radius-s);
  cursor: pointer;
  span {
    font-size: 0.625rem;
    line-height: 1.2;
    display: block;
    text-align: center;
    margin: 0.3rem 0;
  }
  &:hover {
    opacity: 0.8;
  }
  button {
    display: flex;
    width: 100%;
    height: 100%;
    border: 2px solid transparent;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    p {
      margin-top: 0.2rem;
      font-size: 0.625rem;
      color: var(--color-secondary);
      line-height: 1.1;
      text-align: center;
    }
  }
}
.select {
  font-size: 0.725rem;
  color: var(--color-secondary);
  font-weight: 400;
}

.statusDescription span {
  font-size: 0.785rem;
  color: var(--color-secondary);
}

.onboarding {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0.5rem;
  background: var(--color-primary);
  border-radius: 10px;
  font-size: 0.825rem;
  line-height: 1.3;
  margin: 0.5rem 0;
  color: #000;
  svg {
    min-width: 16px;
  }
}
