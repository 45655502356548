.header {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 1rem;
}
.content {
  box-sizing: border-box;
  padding: 1rem;
  height: 100%;
  h2 {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
}
.wrapper {
  flex-grow: 1;
  margin-bottom: 5rem;
}
.action {
  margin: 3rem 0;
  display: flex;
  justify-content: center;
}
.form {
  width: 360px;
  box-sizing: border-box;
  padding: 0 1rem;
  max-width: 360px;
  min-width: 360px;
  border-right: 1px solid var(--color-input-bg);
  height: calc(100vh - 57px);
  max-height: calc(100vh - 57px);
  position: sticky;
  top: 0;
  overflow-y: auto;
}
.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 2rem;
  align-items: stretch;
  // margin-bottom: 10rem;
}
