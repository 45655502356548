.box {
  display: flex;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    p {
      max-width: 120px;
      white-space: nowrap; /* Prevents text from wrapping to the next line */
      overflow: hidden; /* Ensures the overflow is hidden */
      text-overflow: ellipsis;
    }
  }
  img {
    margin-right: 0.5rem;
    min-width: 30px;
  }
}

.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 0.9rem;
  border-radius: 50%;
  background: #262628;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 0.4rem;
  @media screen and (max-width: 768px) {
    width: 36px;
    height: 36px;
    font-size: 0.8rem;
  }
}
.userMenu {
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 8px;
}
.userMenuActions button {
  width: 100%;
  span {
    font-size: 1rem;
  }
  &:hover {
    opacity: 0.7;
  }
}
.actionsFooter button span {
  color: red;
}
