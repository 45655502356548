.list {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding-bottom: 1rem;
  max-width: 100%;
}
.wrapper {
  margin-bottom: 2rem;
}
.snapDetails {
  display: flex;
  align-items: center;
  gap: 1rem;

  font-size: 0.785rem;
  color: var(--color-secondary);
  @media screen and (max-width: 768px) {
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
  img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 4px;
  }
  span {
    display: flex;
    align-items: center;
  }
}
.promptText {
  margin-top: 0.3rem;
  font-size: 0.925rem;
  line-height: 1.4rem;
  span {
    color: var(--color-secondary);
    user-select: none;
  }
  button {
    display: inherit;
    position: relative;
    left: 3px;
    top: 3px;
    &:hover {
      opacity: 0.6;
    }
  }
}
.snapDetailItem {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  svg {
    max-height: 14px;
  }
}
.loadedBlock {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: var(--border-radius-s);
  background: #020312;
}

.pending {
  background: black;
  opacity: 0.1;
  filter: blur(12px);
}
