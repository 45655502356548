.button {
  position: fixed;
  bottom: 1.5rem;
  right: 2rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  border-radius: 50%;
  background-color: #1e1c1c;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (max-width: 768px) {
    bottom: 70px;
    right: 1rem;
  }
}
