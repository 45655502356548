.wrapper {
  margin: 3rem 0;
  margin-top: 1rem;
  box-sizing: border-box;
  border-radius: 12px;
  // background-color: #020312;
  // box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  //   rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  h2 {
    font-size: 1.2rem;
    font-style: italic;
    color: var(--color-secondary);
    text-align: center;
  }
}
.freeWrapper {
  box-sizing: border-box;
  padding: 2rem;
  // box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  //   rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  h2 {
    font-size: 1.2rem !important;
    font-weight: 400 !important;
  }
  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
}

.priceFree {
  font-size: 2.8rem;
  font-weight: bold;
  text-align: center;
  line-height: 3.4rem;
  margin-top: 2rem;
}

.descriptionFree {
  text-align: center;
  margin-top: 1rem;
  color: var(--color-secondary);
}
.freeList {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.freeItem {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  line-height: 1.2;
  font-weight: 500;
  p {
    color: var(--color-secondary);
    margin-top: 0.2rem;
    font-weight: 400;
  }
}

.freePriceAction {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  // a {
  //   display: flex;
  //   justify-content: center;
  //   text-align: center;
  // }
}

.paidWrapper {
  box-sizing: border-box;
  padding: 1rem;
  margin-top: 2rem;
  background-color: var(--color-surface);
  border-radius: 0 0 12px 12px;
  h2 {
    font-size: 1.2rem !important;
    font-weight: 400 !important;
  }
}

.pricePaid {
  font-size: 2.2rem;
  font-weight: bold;
  text-align: center;
  line-height: 3rem;
  margin-top: 2rem;
  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    line-height: 2.2rem;
  }
}

.others {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-left: 1rem;
    font-size: 0.925rem !important;
    color: var(--color-secondary);
    margin-top: 0;
  }
  img {
    max-height: 36px;
    border-radius: 50%;
    margin: 0 -6px;
    vertical-align: middle;
    border: 2px solid var(--color-surface);
  }
  div {
    display: flex;
  }
}

.paidPriceItem {
  display: flex;
  max-width: 880px;
  margin: 0 auto;
  margin-top: 2rem;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;
    border-radius: 12px;
  }
}

.paidPriceItemLeft {
  box-sizing: border-box;
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #020312;
  border-radius: 12px 0 0 12px;
  a {
    margin-top: 4rem;
  }
  div {
    font-size: 2.2rem;
    line-height: 1.2;
    font-weight: bold;
  }
  p {
    margin-top: 2rem;
    color: var(--color-secondary);
  }
}
.paidPriceItemRight {
  box-sizing: border-box;
  padding: 3rem 2rem;
  background-color: #1e1c1c;
  flex-grow: 1;
  border-radius: 0 12px 12px 0;
}
.priceBadge {
  box-sizing: border-box;
  padding: 0.1rem 0.6rem;
  border-radius: 0.2rem;
  color: var(--color-secondary);
  background-color: #000;
  font-weight: 500;
  display: inline-block;
}
.pricePaidHeader {
  display: flex;
  align-items: center;
}
.priceDescription {
  margin-left: 1rem;
  font-size: 0.875rem;
  color: var(--color-secondary);
  font-style: italic;
}
.paidList {
  margin: 0 auto;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.paidItem {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  line-height: 1.2;
  font-weight: 500;
  font-size: 0.875rem;
  p {
    color: var(--color-secondary);
    margin-top: 0.2rem;
    font-weight: 400;
  }
}

.manage {
  margin-top: 2rem;
}
.selectedPlan {
  border: 2px solid var(--color-primary);
  border-radius: 10px;
}
.buy {
  span {
    top: 0.8rem;
    font-size: 0.925rem;
  }
  input {
    height: 50px;
    font-size: 1rem;
  }
  button {
    height: 50px;
    margin-right: -10px;
    width: 60px;
    justify-content: center;
  }
  button span {
    padding-top: 0;
    margin-top: 0;
    color: #000;
  }
}

.buyDetails {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  p {
    font-size: 0.825rem;
    color: var(--color-secondary);
  }
}
