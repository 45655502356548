.badge {
  display: inline-block;
  padding: 0.125rem 0.62rem;
  border-radius: var(--border-radius-s);
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25;
  border: 1px solid transparent;
}

.warning {
  background-color: var(--color-badge-warning-bg);
  color: var(--color-badge-warning);
}

.success {
  background-color: var(--color-badge-success-bg);
  color: var(--color-badge-success);
}

.fail {
  background-color: var(--color-badge-fail-bg);
  color: var(--color-badge-fail);
}

.info {
  background-color: var(--color-badge-fail-bg);
  color: var(--color-badge-info);
}
