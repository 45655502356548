.form {
  margin-top: 1rem;
  margin-bottom: 5rem;
  textarea {
    min-height: 78px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
}
.concurrencyFilled {
  position: absolute;
  font-size: 0.725rem;
  top: -22px;
  color: var(--color-secondary);
}
.note {
  font-size: 0.785rem;
  color: var(--color-secondary);
  opacity: 0.7;
}
.promptLink {
  margin-left: auto;
  font-size: 0.825rem;
  color: var(--color-primary);
  opacity: 0.7;
}
.actions {
  display: flex;
  gap: 0.5rem;
  button {
    height: 28px;
  }
}
.formWrapper {
  margin-top: 1rem;
  border-top: 1px solid var(--color-input-bg);
  background: #020312;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 12px;
}
.actionsDescriptions {
  font-size: 0.725rem;
  color: var(--color-secondary);
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
}
.advanced {
  button {
    width: 100%;
    outline: none !important;
    svg {
      margin-left: auto !important;
    }
    span {
      font-size: 1rem;
    }
  }
}

.formControl label {
  display: flex;
  //justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: var(--color-secondary);
  span {
    font-size: 0.875rem;
    color: var(--color-secondary);
  }
}
.rangeValue {
  box-sizing: border-box;
  padding: 0.4rem;
  border: 1px solid var(--color-border-input);
  border-radius: 4px;
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 18px;
}
.rangeBox {
  display: flex;
  gap: 1rem;
  align-items: center;
  div {
    font-size: 0.675rem;
    font-weight: bold;
  }
  input[type='range'] {
    width: 100%;
  }
  input[type='text'] {
    width: 100%;
    font-weight: 400;
    & + div {
      width: 100%;
    }
  }
}

.checkBox {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 0.675rem;
    font-weight: bold;
  }
  input[type='range'] {
    width: 100%;
  }
  input[type='text'] {
    width: 100%;
    font-weight: 400;
    & + div {
      width: 100%;
    }
  }
}

.checkBoxPublic {
  display: flex;
  gap: 1rem;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 0.675rem;
    font-weight: bold;
  }
  input[type='range'] {
    width: 100%;
  }
  input[type='text'] {
    width: 100%;
    font-weight: 400;
    & + div {
      width: 100%;
    }
  }
}

.formTitle {
  font-weight: bold;
}
.formControl {
  margin-bottom: 0.5rem;
}
.inputWrapper {
  width: 100%;
}
.inputWrapper div {
  width: 100%;
}

.formAction {
  margin-top: 1.5rem;
  position: sticky;
  bottom: 10px;
  box-sizing: border-box;
  padding: 0 1rem;
  button {
    height: 50px;
    &:disabled {
      opacity: 1;

      filter: grayscale(1);
    }
    span {
      font-size: 0.925rem;
      position: relative;
      top: -10px;
    }
  }
  & > span {
    position: absolute;
    color: #000;
    opacity: 0.45;
    font-size: 0.725rem;
    bottom: 4px;
    left: 0;
    right: 0;
    text-align: center;
    pointer-events: none;
  }
}

.modelsWrapper {
  background: #020312;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 12px;
}

.balanceWidget {
  margin-bottom: 1rem;
}
.negativeRestore {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
