.container {
}

.containerBox {
  display: flex;
  align-items: center;
  button {
    margin-left: 0.3rem;
    font-weight: 500;
    span {
      font-size: 0.9rem;
      margin-right: 0.5rem;
    }
  }
  @media screen and (max-width: 768px) {
    button {
      display: none;
      // position: absolute;
      // left: 0;
      // right: 0;
      // bottom: -2px;
    }
    margin-right: 1rem;
    margin-top: 3px;
    svg {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
}

.hidden {
  border-radius: var(--border-radius-s);
  box-shadow: var(--shadow-notification);
  background-color: var(--color-input-bg);
  overflow: hidden;
  button {
    width: 100%;
    border-radius: 0;
    &:hover {
      background-color: var(--tabs-bg);
    }
  }
  button span {
    font-size: 0.9rem;
    padding: 0.3rem 1.5rem;
    text-align: right;
  }
}
.mobileLang {
  @media screen and (min-width: 768px) {
    display: none;
  }
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 23px;
  width: 26px;
  text-align: center;
  font-size: 0.525rem;
  color: var(--color-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
