.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  outline: none;
  input:checked + div {
    background-color: var(--color-primary);
  }
  input:focus + div {
    box-shadow: 0 0 1px #2196f3;
  }
  input:checked + div:before {
    transform: translateX(18px);
  }
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
