.logo {
  display: flex;
  align-items: center;
  position: relative;
  img {
    margin-right: 0.6rem;
    max-height: 44px;
  }
  a {
    font-size: 1.5rem;
    font-weight: 600;
  }
  p {
    font-weight: 600;
    letter-spacing: -1.5px;
    font-size: 1.5rem;
    color: var(--color-text) !important;
  }
  span {
    position: absolute;
    color: var(--color-primary);
    font-weight: 600;
    font-size: 0.425rem;
    right: -24px;
    bottom: 0;
  }
}
