.content {
  box-sizing: border-box;
  padding: 1rem;
  // height: 100%;
  margin-bottom: 5rem;
  h2 {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
}
.wrapper {
  flex-grow: 1;
  margin-bottom: 5rem;
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 2rem;
  align-items: stretch;
  // margin-bottom: 10rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    & > div {
      width: auto;
    }
  }
}
