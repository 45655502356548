.wrapper {
  width: 100%;
}
.nav {
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-left: 0;
  li {
    a {
      display: block;
      box-sizing: border-box;
      font-size: 0.875rem;
      padding: 0.1rem 0.5rem;
      // background-color: var(--color-input-bg);
      border-radius: 1.5rem;
      transition: all 0.3s ease;
      &:hover {
        background-color: #262628;
      }
    }
  }
}
ul a.active {
  background: red;
}
.navLink {
  &.active {
    background: red;
  }
}
.active {
  background: red;
}
