.errorField {
  color: var(--color-red);
  font-size: 0.825rem;
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
}
