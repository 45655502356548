.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 2rem 2rem;
}
.loaderBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 222;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
}
.topControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  button {
    width: 24px;
    height: 24px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.wrapper {
  box-sizing: border-box;
  max-width: 600px;
  width: 600px;
  margin: 0 auto;
}

.controlBlock {
  margin-bottom: 1rem;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  h1 {
    text-align: center;
    font-weight: bold;
    font-size: 2.4rem;
  }
}

.action {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.uploadBox {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin-top: 2rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
  }
}
.photoPreviewBig {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
  img {
    object-fit: cover;
  }
}
.photoPreview {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
}
.filePreview {
  position: relative;
  display: flex;
  margin-bottom: 1rem;
  button {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.3s ease;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
}
.photoPreviewImg {
  //max-width: 33%;
  flex-grow: 1;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  border-radius: 0.2rem;
}
.smallTitle {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: var(--color-secondary);
}
.expectedResult {
  width: 400px;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.finalResult {
  width: 50%;
  min-width: 50%;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
  span {
    font-size: 16px !important;
  }
  p {
    margin-bottom: 1rem;
  }
  label {
    height: 200px;
  }
}
.protection {
  margin-top: 1rem;
  text-align: center;
  font-size: 0.625rem;
  color: var(--color-secondary);
}

.uploader {
  height: 300px;
}

.invalid {
  border: 2px solid red;
  border-radius: 8px;
}
.error {
  position: absolute;
  top: 105%;
  text-align: center;
  font-size: 0.685rem;
  line-height: 11px;
  color: var(--color-secondary);
}
.photosCount {
  color: var(--color-secondary);
  font-size: 0.885rem;
}
.instructions {
  font-size: 0.825rem;
  line-height: 1.3;
  svg {
    margin-right: 5px;
    position: relative;
    top: 2px;
  }
}

.image {
  display: flex;
  justify-content: center;
  img {
    max-width: 350px;
    @media screen and (max-width: 768px) {
      max-width: 100%;
      height: auto;
    }
  }
}

.skip {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}
