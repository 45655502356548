.listItem {
  position: relative;
  aspect-ratio: 13 / 19;
  display: flex;
  min-width: 250px;
  width: 250px;
  border-radius: 8px;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    .floatBox {
      opacity: 1;
    }
  }
  @media screen and (max-width: 768px) {
    min-width: 150px;
    width: 150px;
  }
}
.floatBox {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  display: flex;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.69));
  justify-content: flex-end;
  flex-direction: column;
  right: 0;
  box-sizing: border-box;
  padding: 1rem;
  color: #fff;
  overflow-y: auto;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  @media screen and (max-width: 768px) {
    opacity: 1;
    background: transparent;
  }
}
.fav {
  position: absolute;
  top: 1rem;
  right: 3rem;
}
.download {
  position: absolute;
  top: 1rem;
  right: 1rem;
  svg {
    max-width: 18px;
  }
}
.copy {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 2;
  button {
    &:hover {
      opacity: 0.9;
    }
  }
}

.upscale {
  position: absolute;
  top: 0.87rem;
  left: 3rem;
  display: flex;
  a {
    font-weight: 600;
    font-size: 0.875rem;
  }
  svg {
    width: 22px;
    height: 22px;
  }
  button {
    span {
      font-size: 1rem;
      font-weight: 600;
    }
    &:hover {
      opacity: 0.9;
    }
  }
}

.model {
  font-size: 0.625rem;
  text-transform: uppercase;
  color: var(--color-secondary);
  display: flex;
  align-items: center;
  font-weight: 500;
  justify-content: space-between;
}
.inputText {
  font-size: 0.825rem;
  line-height: 1.4;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.date {
  margin-top: 0.5rem;
  font-size: 0.785rem;
  opacity: 0.6;
}
object {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius-s);
}
.fallbackBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  font-weight: bold;
  justify-content: center;
  background: linear-gradient(
    0.0000056456deg,
    var(--token-39970de2-5fe4-42ad-ab53-d8274d5c9ad9, var(--color-surface)) 0%,
    var(--token-cb02979d-a542-4279-9758-de55dd3af50c, #1e1c1c) 100%
  );
}

.blurred {
  filter: blur(18px);
}
.nsfw {
  position: absolute;
  top: 3rem;
  z-index: 3;
  left: 1rem;
  background: #f96f7c;
  padding: 0 4px;
  border-radius: 4px;
  line-height: 1;
  span {
    font-size: 10px;
    line-height: 1;
    margin-right: 5px;
  }
  svg {
    max-width: 12px;
  }
}
