.content {
  box-sizing: border-box;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 7rem;
  img {
    max-width: 600px;
    border-radius: 2rem;
    margin: 0 auto;
    margin-top: 3rem;
  }
  h1 {
    font-size: 3.6rem;
    line-height: 4rem;
    font-weight: bold;
  }
  p {
    margin-top: 1.5rem;
    font-size: 1.4rem;
    color: var(--color-secondary);
    span {
      background-color: var(--color-primary);
      color: #fff;
    }
  }
}
.loaderContainer {
  box-sizing: border-box;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 20px;
  margin-top: 2rem;
  background: var(--tabs-bg);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
